@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

body {
    margin: 0px;
    font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #f4f4f4;
    font-size: 0.8125rem;
    line-height: 1.53846;
    padding: 0px;

    background-color: #f7f6f9;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e2e2e2' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

a {
    color: #1771ff !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.wrapper {
    padding: 0px;
}

.syncit-load-text {
    display: none;
}

.paginationHeader {
    text-align: right;
}

.btn {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 3px 10px;
}

.btn-disabled {
    color: #ccc !important;
}

.link {
    cursor: pointer;
}

.btn-success {
    color: #fff !important;
}

.btn-xs {
    padding: 4px 14px;
}

.right {
    text-align: right !important;
    justify-content: flex-end !important;
}

.form-group label {
    color: #111;
}

#container {
    background-color: #f2f2f2 !important;
}

.panel-success.panel-bordered {
    border: 1px solid #ccc !important;
    box-shadow: 2px 2px 2px #eee !important;
}

tr:hover {
    background-color: transparent !important;
}

td:hover {
    background-color: transparent !important;
}

td,
th {
}

th{
    background-color: #ddd;
}

i.fa-trash:hover {
    color: #cc0000;
}

.swal2-popup {
    font-size: 1em !important;
}

.playbackHolder {
    padding: 0px !important;
    margin: 0px !important;
}

.playbackHolder .playerRow {
    width: 100% !important;
}

.sessionInfoHeader {
    width: 100% !important;
}

.sessionViewerMain {
    background-color: #fff;
    border: 1px solid #ccc;
    position: relative;
}

#viewerRightColumn {
    top: 5px;
    right: 5px;
    background: #f2f2f2;
}

.page {
    position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 600;
    color: #1771ff;
}

.lefticon {
    margin-right: 7px;
}

.avatar {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    background-size: cover;
}

.avatar img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

a.logoutLink:hover {
    color: #fff !important;
}

.btnReplay {
    width: 45px;
    height: 45px;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #ddd !important;
    border: 1px solid #eee;
    padding: 13px 15px !important;
}

.btnLiveView {
    width: 45px;
    height: 45px;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #ddd !important;
    border: 1px solid #eee;
    background-color: #cc0000;
    padding: 13px 10px !important;
}

.btnLiveViewInactive {
    width: 45px;
    height: 45px;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #ddd !important;
    border: 1px solid #eee;
    background-color: #ff5e00;
    padding: 13px 10px !important;
}

.btnReplay i,
.btnLiveView i,
.btnLiveViewInactive i {
    font-size: 1.3em;
}

.sessionListActionColumn {
    max-width: 77px;
    width: 77px;
    border-right: 1px solid #ddd;
    text-align: center;
}

.center {
    text-align: center;
}

.paginationButtonDiv a,
.paginationButtonDiv span {
    margin-left: 5px;
}

.paginationButtonDiv a,
.paginationButtonDiv span {
    padding: 2px 14px 0px 14px;
}

.paginationButtonDiv i {
    font-size: 1.7em;
}

.btnPlayBanner {
    padding: 1px 5px;
    background-color: #1771ff;
    font-size: 1em;
    color: #fff !important;
    border-radius: 3px;
}

.dashboardLiveCell {
    border-radius: 5px;
    padding: 25px;
    border: 1px solid #ccc;
}

.col-4 {
    float: left;
}

.dashboardLiveCell button {
    width: 80px;
    height: 80px;
    border-radius: 7px;
}

.dashboardLiveCell i {
    font-size: 3em;
}

.versionInfo {
    font-size: 1.1em;
    text-align: center;
    opacity: 0.7;
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.versionInfoLogin {
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.sessionsListTable td {
    color: #333;
}

.sessionListShortCode {
    background: #eee;
    width: auto;
    display: inline;
    padding: 1px 7px;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #ccc;
    border-left: 3px solid #a6c9ec;
    font-size: 0.8em;
    font-weight: 700;
}

.rightSectionContentBox {
    padding: 7px;
}

.rightSectionContentRow {
    margin-bottom: 5px;
}

.form-group label {
    font-weight: 600 !important;
}

input.form-control,
select.form-control {
    font-size: 1.2em;
    border: 1px solid #ddd !important;
    box-shadow: 1px 1px 1px #f5f5f5;
}

select {
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'><path d='M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z'/></svg>");
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.noshadow {
    box-shadow: none !important;
}

.rightColumnHeader label {
    color: #000;
    line-height: 0.4em;
}

.helpRequestedLabel {
    background: #ffcc00;
    width: auto;
    display: inline;
    color: #000;
    padding: 2px 11px;
}

.expired {
    color: #555;
    background-color: #ccc;
}

.MuiButton {
    border-radius: 5px;
}

.MuiButton-label {
    font-family: nunito-sans, sans-serif;
}

.MuiTypography-h6 {
    font-family: nunito-sans, sans-serif !important;
    line-height: 1.4 !important;
    letter-spacing: 0.0025em !important;
    font-weight: 700 !important;
    color: #111 !important;
}

.MuiTypography-body1 {
    font-size: 1rem;
    font-family: nunito-sans, sans-serif !important;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.001em;
}

.MuiDialogActions-root {
    flex: 0 0 auto;
    display: flex;
    padding: 15px !important;
    align-items: center;
    justify-content: flex-end;
}

.MuiDialog-paper {
    border-radius: 5px !important;
}

.MuiButton-containedPrimary {
    background-color: #1771ff !important;
}

.codeSnippet {
    margin-top: 10px;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid #ccc !important;
    box-shadow: 2px 2px 2px #eee;
    background-color: #444 !important;
}

.codeSnippet span,
.codeSnippet pre,
.codeSnippet code {
    background-color: #444 !important;
    color: #d8d8d8 !important;
}

.sessionListURLDetail {
    color: #999;
}

.helpRequestHolder {
    padding: 15px;
    background: #ffcc00;
    color: #000;
    border: 1px solid #000;
}

.helpRequestHolder h5 {
    color: #000;
}

.helpRequestHolder button {
    background-color: #7dab45;
    border-color: #7dab45;
    color: #fff;
    font-weight: 700;
}

.assignedToHelpHolder {
    padding: 15px;
    background: #7dab45;
    color: #fff;
    border: 1px solid #7dab45;
}

.assignedToHelpHolder h5 {
    color: #000;
}

.assignedLabel {
    background: #7dab45;
    width: auto;
    display: inline;
    color: #fff;
    padding: 2px 11px;
}

.assignedRow {
    background: rgb(125, 171, 69);
    background: linear-gradient(180deg, rgba(125, 171, 69, 0.2) 48%, rgba(125, 171, 6, 0.5) 100%);
}

.form-control {
    font-size: 13px;
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.07);
    transition-duration: 0.5s;
}

p {
    margin: 2px !important;
}

#mainnav {
    background: #fff;
    border-bottom: 1px solid #ccc;
    width: 100%;
    padding: 10px 25px;
}

.headerLogo {
    height: 40px;
    padding-left: 20px;
    margin-bottom: 7px;
}

.breadcrumb {
    background: transparent !important;
}

.wizardPage {
    margin-left: 20%;
    margin-right: 20%;
}

.formLabel {
    font-weight: 500;
    font-size: 1.1em;
}

select.form-control {
    appearance: none;
}

.formRow {
    margin-bottom: 24px;
}

.wizardPanel {
    padding: 14px;
    background: #fff;
}

.formDivider {
    margin: 21px 0px;
    border-top: 1px dashed #ddd;
    border-bottom: 1px solid #fff;
    height: 2px;
    width: 100%;
}

.btn-big {
    padding-top: 10px;
    padding-bottom: 10px;
}

.bigIconTitle {
    font-size: 1.7em;
    color: #111;
}

.bigIconButton {
    border: 1px solid #ccc;
    border-radius: 7px;
    text-align: center;
    padding: 20px;
    box-shadow: 2px 2px 2px #eee !important;
    cursor: pointer;
}

.bigIconButton .fa {
    font-size: 5em;
    margin-bottom: 21px;
}

.bigIconButton:hover {
    border: 1px solid #777;
}

.headerProfile {
    padding: 10px;
    text-align: right;
}

.bigIconButton a {
    text-decoration: none;
    color: #111;
}

.infoPill {
    padding: 3px 7px;
    background: #f5f5f5;
    width: auto;
    display: inline;
    border-radius: 7px;
}

.sentenceDisplay {
    font-size: 1.5em;
}

.voiceTitle {
    font-size: 1.6em;
}

.progressBar {
    background: #ddd;
    width: 100%;
    height: 16px;
    margin: 4px;
    padding: 2px;
    border-radius: 7px;
    box-shadow: 2px 2px 2px #eee !important;
}

.progressBarInner {
    background: #aaa;
    height: 12px;
    border-radius: 5px;
}

.testWidth {
    width: 500px;
}

.btnWider {
    padding: 14px 25px;
}

.sequencePage .MuiOutlinedInput-input {
    padding: 7px 10px 4px 10px !important;
}

.sequencePage .MuiOutlinedInput-notchedOutline legend span {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #111;
}

.btn-sm i.fa {
    font-size: 0.7em;
    line-height: 0.5em;
}

.topMenu {
    float: left;
    font-size: 1.4em;
    padding: 10px 5px 0px 10px;
    margin-left: 20px;
}

.listActionItem {
    transition-duration: 0.2s;
    border: 1px solid #aaa;
    width: auto;
    display: inline;
    padding: 4px 9px;
    font-size: 0.9em;
    border-radius: 5px;
    margin-right: 5px;
}

.listActionItem:hover {
    border: 1px solid #333;
    color: #008800;
}

select {
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'><path d='M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z'/></svg>");
    background-repeat: no-repeat;
    background-position: right 10px center;
    -webkit-appearance: none !important;
}

#leftnav {
    padding: 25px 0px 25px 0px;
    border-right: 1px solid #ddd;
    width: 220px;
    min-width: 220px !important;
    min-height: -moz-available !important;
    min-height: -webkit-fill-available!important;
    min-height: fill-available!important;
    border-radius: 0px !important;
    background: #eee;
}

.mainContainer {
    background: transparent !important;
    padding: 0px 0px 17px 0px;
    display: inline;
    overflow: auto;
    vertical-align:top !important;
    margin: 0px;
    top: 0px;
}

#mainColumn {
    width: calc(100% - 220px) !important;
}

.mainPanel {
    padding: 40px 50px 50px 50px;
    width: 100%;
    margin-right: 20px !important;
}

.filterPanel{    
    padding: 20px 32px 0px 35px;
    width: 100%;
    margin-right: 40px !important;
}


.breadcrumb li {
    font-size: 1.3em;
    font-weight: 500;
    text-transform: capitalize;
}

.breadcrumb li:after {
    /* using style CSS custom property */
    font: var(--fa-font-solid);
    content: "\f054";
    margin: -3px 10px 0px 10px;
    font-size: 0.5em;
    vertical-align: middle;
}

.breadcrumb li:last-child:after {
    content: "" !important;
}

ul.leftnavLinks {
    list-style: none !important;
    margin-top: 20px;
    padding: 0px;
}

ul.leftnavLinks li {
    list-style: none !important;
    padding: 10px 10px 10px 0px;
    font-size: 1.3em;
    width: 100%;
    display: block;
}

ul.leftnavLinks li a {
    color: #555 !important;
    text-decoration: none !important;
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 5px;
    width: 100%;
    display: block;
}

ul.leftnavLinks li a i {
    vertical-align: middle;
    font-size: 1.5em;
    margin-right: 7px;
}

ul.leftnavLinks li a:hover {
    text-decoration: none !important;
    color: #111 !important;
}

.leftBottomBox {
    bottom: 20px;
    left: 17px;
    position: fixed;
}

.progressBox {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 180px;
}

.remainingWords {
    font-size: 2em;
    padding: 0px 5px;
    color: #1771ff;
}

.progressBarInner {
    background: #009900;
}

.smInfo {
    font-size: 0.7em;
    padding: 0px 20px;
}

.projectBox {
    padding: 0px;
    width: auto;
    min-width: 30% !important;
    margin: 0px 20px 20px 0px;
    position: relative;
    border: 1px solid #FFF !important;
    transition: all 0.3s;
}

.projectBox:hover {
    background: #FFF;
    border: 1px solid #ccc !important;
}


.projectBox  a{
    text-decoration: none;
}

.projectBox h4{
    line-height: 0.6em;
    padding-top: 7px;
    font-size: 1.5em;
    min-height: 30px;
}

.darkProjectBox {
    background-image: linear-gradient(181deg, rgb(255, 255, 255) -1.7%, rgb(248, 248, 248) 85.6%) !important;
    color: #111 !important;
}

.darkProjectBox h4 {
    color: #111 !important;
}

.profileBox {
    padding: 10px;
    margin-bottom: 10px;
}

.addButton {
    padding: 8px 14px 8px 14px !important;
    vertical-align: middle !important;
    background-color: #FFF !important;
    color: #555 !important;
}

.addButton .fa-solid {
    font-size: 1.5em !important;
}

.addButtonText {
    float: right !important;
    padding: 1px 0px 0px 11px !important;
}

Button {
    font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

div,
.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6 {
    font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: -0.03em !important;
    font-weight: 400 !important;
}

.MuiInput-root:after {
    border-bottom: 2px solid #3ed219 !important;
}

.MuiButton-root {
    color: #174692 !important;
    font-weight: 500 !important;
    letter-spacing: -0.03em !important;
    text-transform: none !important;
}
.MuiInputLabel-root {
    color: #333 !important;
}

.cancelButton {
    background-color: #FFF !important;
    border: 1px solid #ccc !important;
}

Button.lightSquare {
    background-color: #fff !important;
    color: #aaa !important;
    padding: 4px !important;
    min-width: 0px !important;
    border: 1px solid #fff;
}

Button.lightSquare:hover {
    background-color: #fff !important;
    color: #222 !important;
    padding: 4px !important;
    min-width: 0px !important;
    border: 1px solid #ccc;
}

.avatarInList {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
}

.avatarInListCropper {
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    float: left;
    vertical-align: middle;
    margin: 0px 21px 0px 0px;
    border: 2px solid #ccc !important;
    transition: all 0.2s;
}

.avatarInListCropper:hover {
    border: 2px solid #6699CC !important;
}

.avatarInListLabel {
    float: left;
    vertical-align: middle;
    padding: 10px 4px 10px 0px;
    font-weight: 500;
}

#mui-component-select-voice_id img {
    height: 40px;
    width: 40px;
}

.avatarInSelectCropper {
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    float: left;
    vertical-align: middle;
    margin: 0px 10px 0px 0px;
}

.avatarInSelect {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
}

.accountMenu {
    position: absolute;
    top: 20px;
    right: 20px;
}

.MuiMenuItem-root {
    font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.audio-react-recorder__canvas {
    width: 125px !important;
    height: 70px !important;
}

.recordingBar {
    background-color: #545454;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23303030' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    padding: 11px 0px 11px 0px;
    border-radius: 4px;
}

.MuiTableContainer-root {
    box-shadow: none !important;
}

.MuiTableCell-root {
    font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-size: 0.78rem !important;
}

.ReactTags__tagInputField {
    padding: 10px !important;
    width: 100% !important;
}

.ReactTags__tag {
    border: 1px solid #555;
    padding: 5px 2px 6px 5px;
    margin-right: 5px;
    background: #6699cc;
    color: #fff;
}

.ReactTags__selected {
    margin-bottom: 8px;
}

.ReactTags__remove {
    margin-left: 5px;
    border: 1px solid #111;
}

#fileInput{
    display: none;
}

.fileInputLabel{
    width: 100%;
}

.voiceFileLine{
    margin: 3px;
    border-top: 1px solid #ccc;
    padding: 5px 10px 5px 10px;
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
    border-radius: 3px;
}

.voicePresetBox{
    margin: 3px;
    border-top: 1px solid #ccc;
    padding: 5px 10px 5px 0px;
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
    border-radius: 3px;
    min-height: 100px;
}

.voiceNameInList{
    float: left;
    padding-top: 10px;
}

.voiceAvatarColumn{
    position: relative;
}

@media (min-width: 400px) {
    .voiceNameSpacer{
        padding-left: 15px !important;
    }

    .avatarSpacer{
        width: 85px !important;    
    }
}

@media (min-width: 1000px) {
    .voiceNameSpacer{
        padding-left: 25px !important;
    }

    .avatarSpacer{
        width: 55px !important;    
    }
}

@media (min-width: 1500px) {
    .voiceNameSpacer{
        padding-left: 20px !important;
    }

    .avatarSpacer{
        width: 55px !important;    
    }
}

@media (min-width: 2000px) {
    .voiceNameSpacer{
        padding-left: 20px !important;
    }

    .avatarSpacer{
        width: 40px !important;    
    }
}

.leftnavLinks li{
    position: relative;
    padding: 10px 25px !important;
}

.leftnavLinkActive{
    background: #FFF !important;
}

ul.leftnavLinks li.leftnavLinkActive a{
    color: #000 !important;
}

ul.leftnavLinks li.leftnavLinkActive a div.MuiListItemIcon-root svg {
    color: #000;
}

.leftnavLinkActive:after{
    position: absolute;
    top: 0px;
    right: 0px;
    content: "";
    background: #bbb;
    height: 100%;
    width: 7px;
}

.sequenceTagsList{
    margin-left: 10px;
}

.sequenceTag{
    padding: 1px 5px;  
    border: 1px solid #008800;
    border-radius: 4px;  
    color: #222;
    margin: 0px 3px;
}

.rhap_progress-section, .rhap_additional-controls, .rhap_volume-controls{
    display: none;
}

.rhap_main-controls-button{
    border-radius: 5px;
}

.rhap_main-controls-button svg{
    width: 1.5em;
    height: 1.5em;
}

.uploadStatus{
    background: #FFF;
    border: 2px solid #1771ff;
    border-radius: 4px;
    padding: 3px 11px;
    font-size: 1.3em;
}

.warningNote{
    color: #8c1717;
    padding: 10px;
    border: 1px solid #cc0000;
    background: #ede1e1;
    border-radius: 3px;
}

.fileSectionHeader{
    font-size: 1.2em;
    line-height: 0.8em;
}

.fileSectionSubHeader{
    font-size: 0.7em;
    margin-bottom: 10px;
}

.setDefaultSample{
    position: absolute;
    right: 0px;
    top: 4px;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 1px 5px;
    color: #AAA;
    font-size: 0.7em;
    cursor: pointer;
    transition:all 0.3s ease;
}

.defaultSample{
    border: 1px solid rgb(65, 134, 26);
    color: #111;
    background: #FFF;
    transition:all 0.3s ease;
}

.voiceFileLineDefault{
    background: #a6ff00;
}

.clipTextInput{
    height: calc(100%-30px);
    border-radius: 5px;
    padding: 3px 5px;
    margin: 7px 0px 0px 0px;
    border: 1px solid #FFF;
    transition:all 0.3s ease;
    font-size: 1.2em;
    color: #888;
}

.clipTextInput:hover{
    border: 1px solid transparent;
    color: #111;
}

.clipRowActionButton{
    background: #FFF !important;
    color: #777 !important;
}

.clipRowActionButton:hover{
    background: #FFF !important;
    color: #111 !important;
    border: 1px solid #222;
}

.clipRow{
    background: #FFF;
    padding: 13px 13px 16px 13px;
    border: 1px solid #FFF;
    border-radius: 10px;
    position: relative;
    margin-bottom:5px;
    transition:all 0.1s ease;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.clipRow:hover{
    padding: 13px 13px 16px 13px;
}

.clipTable{
    max-width: 1000px !important;
    margin: 0px auto 0px !important;
    padding: 10px;
    background-color: transparent;
    border-radius: 8px;
}

.clipTable td,
.clipTable th {
    padding: 9px 10px !important;
    margin-bottom: 10px;
}

.clipRowActions{
    max-width: 100px;
    position: absolute;
    right: 10px;
}

[contenteditable]:focus {
    outline: 0px solid transparent;
    border: 1px solid transparent;
    color: #111;
}

.voiceModal{
    width: calc(100% - 100px);
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 20px;
    overflow-y: scroll;
    max-height: 100%;
    max-width: 100%;
    min-width: 100%;
}

.voiceModalHolder{
    padding: 60px 35px;
}

.voiceModalItem {
    padding: 25px;
    width: auto;
    min-width: 200px;
    margin: 0px 20px 20px 0px;
    position: relative;
    border: 1px solid #FFF;
    transition: all 0.3s;
    text-align: center !important;
    cursor: pointer;
}

.voiceModalItem img{
    margin: 0px auto 0px;
    text-align: center !important;
}

.voiceModalItem:hover {
    background: #FFF;
    border: 1px solid #ccc !important;
}

.voiceModalItem  a{
    text-decoration: none;
}

.voiceModalItem h4{
    line-height: 0.6em;
    padding-top: 7px;
    font-size: 0.9em;
    font-weight: 600 !important;
    min-height: 60px;
}


.avatarInTileCropper {
    width: 60px;
    height: 60px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-left: calc(50% - 30px);
}

.avatarInListLink{
    cursor: pointer;
}

.voiceModalItemActive{
    border: 1px solid #6699CC !important;
}

.statusMessage{
    padding: 10px;
    width: 1000px;
}

.progressBarOuter{
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    width: 300px;
    text-align: left;
    background: #ccc;
}

.progressBarInner{
    background: #CC0000;
}

.MuiFormControlLabel-root .MuiTypography-root {
    font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-size: 1.3em;
}

.importSettingsBox{
    background: #eee;
    padding: 20px;
    font-size: 0.8em;
    border-radius: 14px;
}

.sequenceControlBar{
    display: none;
    position: relative;
    height: 15px;
    min-height: 25px;
}

.sequenceControlBar Button{
    font-size: 0.7em;
background-color: #FFF !important;
color: #555 !important;
border: 1px solid #aaa;
border-radius: 10px;
padding: 3px 10px !important;
text-transform:capitalize !important;
}

.activeClipRow{
    border: 1px solid #6699CC;
}

.clipControls{
    height: 0px;
    transition: all 0.2s !important;
    display: none;
}

.effectLabel{
    background: tan;
    width: 65px;
}

.MuiSlider-thumbSizeSmall{
    width: 12px !important;
}

.effectLabelPopover{
}

.effectLabelPopover .MuiPopover-paper{
    overflow: hidden !important;
    padding: 15px 21px 5px 21px;
    margin-top: -3px;
    border: 1px solid #ccc;
}

.effectLabelPopover .MuiSlider-valueLabel{
    top: 0px !important;
    padding: 1px 2px !important;    
    width: 45px !important;
}

.effectLabelText{
    color: #111 !important;
    background-color: #FFF !important;
    box-shadow: none !important;
    border: 1px solid #ccc !important;
    padding: 3px 11px !important;
    font-size: 1.0em !important;
    text-transform: uppercase !important;
    margin-right: 5px !important;
}

.stronger{
    font-weight: 600 !important;
}

.delimiterStyleSelect div{
    color: #111 !important;
    background-color: #FFF !important;
    box-shadow: none !important;
    padding: 3px 1px 4px 1px !important;
    font-size: 0.9em !important;
    text-transform: none !important;
    margin-right: 5px !important;
    width: 120px !important;
}

.delimiterStyleSelect fieldset{
    border: none !important;
}

.effectStyleSelect div{
    color: #111 !important;
    background-color: #FFF !important;
    box-shadow: none !important;
    padding: 3px 10px 4px 10px !important;
    font-size: .8em !important;
    text-transform: uppercase !important;
    margin-right: 5px !important;
    width: 70px !important;
}

.minEffectLabels svg{
    font-size: 1em;
    border: 1px solid #111;
    border-radius: 50%;
    margin-right: 5px;
    margin-top: -2px;
    margin-left: 8px;
}

.minEffectLabels{
    text-transform: uppercase;
}

.leftnavLinks li a div.MuiListItemIcon-root{
    min-width: 35px !important;
}

.leftnavLinks li a{
    padding: 0px !important;
}

.leftnavLinks li a div.MuiListItemIcon-root svg{
    padding-left: 0px !important;
    position: absolute;
    top: calc(50% - 0.70em);
    height: 30px;
    font-size: 1.5em !important;
}

.leftnavLinks li a{
    font-size: 0.9em !important;
}

.leftBottomBox{
    display: none;
}

.alignRight{
    text-align: right !important;
}

.topMenuRight{
    margin-right: 100px !important; 
    margin-top: 3px !important;
}

#page-head{
    background-color: #FFF;
    border-bottom: 1px solid #d8d8d8;
    padding: 17px 0px 0px 7px;
    width: 100%;
}

.projectAudioBox{
    width: 100%;
    padding: 10px;
    background: #FFF;
}

.projectOptionsBar{
    width: 980px  !important;
    margin: 0px auto 0px !important;
    position: relative;
}

.projectOptionsBarContainer{
    background: #FFF;
    padding: 0px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 14px !important;
    width: 100%;
}

.projectOptionsSwitch{
    font-size: 0.8em !important;

}

.projectOptionsSwitchSublabel{
    font-size: 0.7em;
    line-height: 11px !important;
    padding-bottom: 0px !important;
}

.projectOptionsTitle{
    font-size: 22px;
    letter-spacing: -0.03em;
    position: relative;
}

.projectOptionsColumn{
    border-right: 1px solid #ccc;
    padding: 4px 0px !important;
    margin-left: 21px;
}

.projectOptionsSwitchLabel{
    letter-spacing: -0.03em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px !important;
    position: relative;
    top: 7px;
}

.vertCenter{
    align-items:center;
  display: flex;
}

.settingToggleButton{
    padding: 3px 10px !important;
    letter-spacing: -0.04em !important;
}

.noPadding{
    padding: 0px !important;
    margin: 0px !important;
}

.tightButton{
    border: none !important;
    border-radius: 0px !important;
    height: 100% !important;
}

.btn-white{
    background-color: #FFF !important;
}

.joinClipsColumn{
    position: absolute;
    right: 0px;
    height: 100% !important;
}

.medGray{
    color: #777;
}

.modalCloseButton{
    text-align: right !important;
    color: #222 !important;
    background-color: #FFF !important;
}

.statusHolder{
    background: transparent;
    text-transform: uppercase;
    font-size: 0.8em;
    margin-top: 4px;
    text-align: center;
}

.sequenceStatus{
    background-color: transparent;
}

.statusLabel{
    border: 1px solid #eee;
    padding: 2px;
    border-radius: 3px;
    background: #FFF;    
    transition: all 0.5s;
}

.avatarLabelInRow{
    color: #888;
    font-size: 0.9em;
}

.clipRowDragger{
    width: 20px;
    display: table-cell;
    vertical-align: middle;
}

.clipRowDragger svg{
    color: #ccc;
    cursor: pointer;
}

.clipRowDraggerHolder{
    display: table;
    width: 20px;
    max-width: 20px !important;
    padding: 0px 15px 0px 0px !important;
}

.clipRowDragger svg:hover{
    color: #000;
}

.smooth-dnd-container{
    width: 100%;
}

.styleWhileDragging{
    border: 2px solid rgb(28, 125, 78);
    box-shadow:  10px 10px 10px #eee;
    background: rgb(243, 255, 250);
}

.btn-success {
    color: #fff;
    background-color: #1771ff;
    border-color: #1771ff;
}

.btn-success:hover {
    color: #fff;
    background-color: #3281ff;
    border-color: #3281ff;
}


.clipRowPlayInferred{
    border: 1px solid #3281ff !important;
    color: #3281ff !important;
}

.userVoiceIcon{
    position: absolute;
    top: 10px;
    left: 10px;
}

.customVoiceModalItem{
    background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
}

.spinnerContainer {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 100000000;
    opacity: 0.8;
    background: #FFF;
}

.spinnerContainer .spinner{
    display: inline;
    position: absolute;
    top: 25%;
    left: 50%;
}

.projectBoxInfo{
    padding: 25px;
}

.audioColumn{
    padding: 11px 11px;
    border: 1px solid #ddd;
    min-height: 300px;
    margin-top: 5px !important;
}

.audioColumnLeft{
    border-radius: 7px !important;
    max-width: calc(50% - 10px) !important;
    min-width: calc(50% - 10px);
    margin-right: 5px !important;
    background-color: #FFF;
}

.audioColumnCenter{
    max-width: 50% !important;
    min-width: 50%;
    border-radius: 7px !important;
    margin-right: 5px !important;
    background-color: #FFF;
}

.audioColumnFullWidth{
    max-width: calc(100% - 5px) !important;
    min-width: calc(100% - 5px) !important;
    border-radius: 7px !important;
    margin-right: 7px !important;
}

.audioColumnRight{
    border-radius: 7px !important;
    width: auto !important;
}

.extraSmallButton{
    padding: 3px 7px !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    font-size: 1em !important;
}

.audioColumnHeader{
    font-size: 1.4em;
    font-weight: 400;
    letter-spacing: -0.03em;
}

.btnAddAudio{
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 5px;
    font-weight: 900 !important;
    max-width: 30px !important;
    min-width: 30px !important;
}

.btnRemoveAudio, .btnDenoiseAudio, .btnDereverbAudio{
    max-width: 30px !important;
    min-width: 30px !important;
    margin-right: 3px !important;
}

.btnRemoveAudio{
    background-color: #cc0000 !important;
}

.btnRemoveAudio:hover, .btnRemoveAudio i:hover{
    background-color: #ff0000 !important;
    color: #FFF !important;
}

.voiceFileNameColumn{
    width: auto !important;
    max-width: 50% !important;
    margin-top: 4px !important;
}

.audioActionButtonsColumn{
    margin-top: 0px !important ;
    white-space: nowrap !important;
    align-items: flex-end !important;
    text-align: end !important; 
    flex-grow: 1 !important;
    max-width: 100% !important;
}

.publishVoiceButton{
    width: 100%;
    font-weight: 900 !important;
    text-transform: uppercase !important;
    height: 55px;
}

.btnEffects, .btnMods{
    background-color: #CCC !important;
    color: #222 !important;
    font-weight: 500 !important;
    letter-spacing: -0.04em !important;
    text-transform: capitalize !important;
    padding: 4px 7px !important;
    margin-right: 5px !important;
}

.btnEffects .MuiButton-endIcon{
    margin-left: 2px !important;
}

.sourceAudioButton{
    background: #FFF;
    padding: 11px !important;
    margin-left: 10px;
}

.lightGreenFadeBg{
    background-color: #a6ff00;
}

.redButton{
    color:#f62f17;
    background-color: #FFF;
    border-color: #f62f17;
}

.voicePresetsContainer{
    padding-top: 27px;
}

.voiceNameEdit{
    border: 1px solid #CCC;
    padding: 15px;
    background: #FFF;
    border-radius: 7px;
}

select{
    background-color: #FFF !important;
}

.formLabel{
    font-size: 0.9em;
}

legend {
    width: unset;
}

.tabPanelContent{
    background-color: #f5f5f5;
    padding: 10px 30px;
    width: 100%;
    height: 100vh;
}

.tabPanelTitlebar{
    font-size: 22px;
    text-transform: none;
    font-weight: 500 !important;
    background-color: #FFF;
    margin: 0px 0px 10px 0px;
    padding: 14px 40px;
    width: 100%;
    letter-spacing: -0.03em;
    border-bottom: 1px solid #6699CC;
}

.campaignNameTitle{
    margin-left: 15px;
}

.campaignAssetsPanel{
    background: #FFF;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.smallerText{
    font-size: 0.7em;
}

.assetRow{
    padding: 0px 0px 10px 0px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.campaignTemplateWindow{
    background: #000;
    width: 100%;
    height: 650px;
    border-radius: 7px;
}

.formFieldItem{
    border: 1px dashed #888;
    margin: 4px 0px;
    padding: 7px 7px;
    font-size: 12px;
    background-color: #FFF;
}

textarea{
    font-size: 0.8em !important;
}

.circleRed{
    color: #cc0000;
    height: 17px !important;
}

.circleGreen{
    color: #00cc00;
    height: 17px !important;
}

.tableRowInfoCell{
    width: auto;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
}

td, th{
    width: auto;
    display: block;
  overflow: hidden;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
}

.qrCodePreview{
    min-width: 210px !important;
    max-width: 210px !important;
}

.redButton{
    color: #cc0000 !important;
    border: 1px solid #cc0000 !important;
}

.pieChartHolder{
    padding: 20px 50px !important;
}

.qrCodeBox{
    padding: 21px !important;
}

.qrCodeBoxRed{
    border: 2px solid #cc0000 !important;
    padding: 21px !important;
}

.grayButton{
    color: #ccc !important;
    cursor:not-allowed !important;
}

.totalCountRow{
    font-weight: 600 !important;
    font-size: 1.1em !important;
    margin-top: 10px;
    margin-bottom: -10px;
}

.paddedPanel{
    padding: 14px 21px;
}

.smallerHeader{
    font-size: 15px;
}

.errorBold{
    font-size: 0.9em;
    font-weight: 500;
    color: #aa0000;
}

.okBold{
    font-size: 0.9em;
    font-weight: 500;
    color: #00aa39;
}

.sortButtonWaiting{
    color: #888;
}

.selectedColumn{
    background-color: #FFF !important;
}

.centered{
    text-align: center !important;
}

.submitButton{
    background-color: #009900 !important;
    color: #FFF !important;
    border: 1px solid #009900 !important;
}

.danger{
    background-color: firebrick !important;
    border: 1px solid firebrick !important;
}

.requiredField:after{
  content: "*";
  display: inline-block;
}

.textContainer {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btnRefreshLandingPage{
    color: #FFFFFF !important;
    font-weight: bold !important;
    background-color: #003366 !important;
    border-radius: 7px !important;
  }

  .swal2-container{
    z-index: 2147483647 !important;
  }