
body, html, #root{
    height: 100%;
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#LoginContainer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #000;
    opacity: 1;
}

.loginBox {
    background: #fff;
    padding: 45px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
    color: #111;
}

label {
    color: #111;
}

.loginLogo {
    width: 200px;
}

#loginMessage {
    color: #8c1717;
    font-weight: 600;
    margin: 5px;
    padding: 5px;
}

.noshadow {
    box-shadow: none !important;
}

.versionInfoLogin{
    color: #6699cc;
}
