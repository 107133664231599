body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.exportCSVButton{
  font-size: 13px !important;
  letter-spacing: -0.04em;
}

.actionButton{
  border: 1px solid #ccc !important;
  margin-left: 3px !important;
  padding: 0px 7px !important;
}

.customPill{
  background: #ccffff;
  padding: 1px 4px;
  border-radius: 3px;
  border: 1px solid #6699cc;
  font-size: 0.9em;
  margin-bottom: 8px;
}

.descText{
  font-size: 0.7em;
  line-height: 1.3;
}

.uploadButton{
  border: 2px solid #07921c !important;
  color: #FFF !important;
}